
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  computed,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import PaymentsWithRefund from "@/modules/payment/PaymentsWithRefund.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getDropdownsByType } from "@/api/dropdown.api";
import Multiselect from "@vueform/multiselect";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minValue, maxLength } from "@vuelidate/validators";
import { useRoute, useRouter } from "vue-router";
import {
  addPayment,
  addPaymentRefundItemRequest,
  getPayment,
} from "@/api/payment.api";
import { getPatient, searchByName } from "@/api/patient.api";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getPanel } from "@/api/panel.api";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface RevenueCode {
  code: string;
  description: string;
  chargePerUnit: number;
}

export interface Data {
  postingInProgress: boolean;
  isForPatient: boolean;
  totalBalanceDue: number;
  patientId?: string;
  isDirty: boolean;
  isLoaded: boolean;
  payment?: any;
  items: {
    id: string;
    unappliedCredit: number;
    refundAmount?: number;
    paymentId: string;
  }[];
  previousPayment?: any;
  createNewMode: boolean;
  canPost: boolean;
  updateOnly: boolean;
  dict: {
    paymentTypes: [];
    transactionTypes: [];
    adjustmentReasonCodes: any[];
    facilities: [];
  };
  patientOptions: { id: string }[];
}

export default defineComponent({
  name: "PatientPaymentComponent",
  components: {
    Multiselect,
    DecimalInput,
    PaymentsWithRefund,
    DateFloatComponent,
  },
  props: ["paymentId"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";

      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDismissed) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props, ctx) {
    const store = useStore();
    let organizationId = ref<string | null>("");
    const paymentsWithRefund = ref(null);
    const router = useRouter();
    const route = useRoute();
    let data = reactive<Data>({
      postingInProgress: false,
      canPost: false,
      isForPatient: false,
      totalBalanceDue: 0,
      items: [],
      patientId: undefined,
      isDirty: false,
      isLoaded: false,
      payment: undefined,
      createNewMode: true,
      updateOnly: false,
      dict: {
        paymentTypes: [],
        transactionTypes: [],
        adjustmentReasonCodes: [],
        facilities: [],
      },
      patientOptions: [],
    });

    watch(
      () => data.payment,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.previousPayment &&
          JSON.stringify(currentValue) != JSON.stringify(data.previousPayment)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      organizationId.value = getOrganization();
      const panel = await getPanel();
      data.dict.facilities = panel.facilities;
      data.dict.paymentTypes = store.getters.allPaymentTypes;
      data.dict.transactionTypes = store.getters.allTransactionTypes;
      let adjustmentReasonCodes = await getDropdownsByType({
        type: "Adjustment Types",
      });
      data.dict.adjustmentReasonCodes = adjustmentReasonCodes.data;
      if (props.paymentId) {
        setCurrentPageTitle("Patient Payment");
        data.payment = await getPayment(props.paymentId);
        data.createNewMode = false;
        if (data.payment.unappliedCredit > 0) {
          data.canPost = true;
        }
      } else {
        setCurrentPageTitle("Refund for Unapplied Credits");
        data.canPost = true;
        data.createNewMode = true;
        data.payment = {
          id: "",
          type: 2,
          paymentType: 2,
          adjustmentReason: 0,
          entryDate: getUTCnow().toISOString(),
          paymentDate: getUTCnow().toISOString(),
          accountingDate: getUTCnow().toISOString(),
          unallocatedAmount: 0,
          totalAmount: null,
          unappliedCredit: null,
        };
        const patientId = route.query.patientId;
        if (patientId) {
          const patient = await getPatient(patientId);
          data.payment.patient = patient;
          data.payment.patientId = patient.id;
          data.isForPatient = true;
        }
        const type = route.query.type;
        if (type) {
          data.payment.type = type;
        }
      }
      data.patientId = data.payment.patientId;
      data.previousPayment = JSON.parse(JSON.stringify(data.payment));
      data.isLoaded = true;
      v$.value.$validate();
    });

    function getUTCnow() {
      var date = new Date();
      const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return d;
    }

    const adjustmentReq = (payment) =>
      helpers.withParams(
        { type: "adjustmentReq", value: payment },
        (value) => helpers.req(value) || data.payment.type != 1
      );

    const paymentRules = {
      payment: {
        patientId: { required: helpers.withMessage("Required", required) },
        type: { required: helpers.withMessage("Required", required) },
        paymentType: { required: helpers.withMessage("Required", required) },
        paymentDate: {
          paymentReq: helpers.withMessage("Required", required),
        },
        adjustmentReason: {
          adjustmentReq: helpers.withMessage("Required", adjustmentReq(data)),
        },
        totalAmount: {
          required: helpers.withMessage("Required", required),
          minValue: minValue(1),
        },
        reference: { maxLength: maxLength(50) },
        payerId: { required: helpers.withMessage("Required", required) },
      },
    };

    let v$ = useVuelidate(paymentRules, data as never) as any;

    const isSaveEnabled = computed(() => {
      let formErros = v$.value.$silentErrors.length == 0;
      return (
        formErros &&
        (!postedErrorsList.value || postedErrorsList.value.length == 0)
      );
    });

    async function searchPatients(text) {
      const patients = await searchByName({ search: text });
      data.patientOptions = patients;
      return patients;
    }

    function patientSelected(patientId) {
      if (!data.createNewMode) {
        Swal.fire({
          title:
            "Are you sure you want to change the selected Patient Account?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            denyButton: "order-3",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            data.payment.patientId = patientId;
            data.payment.patient = data.patientOptions.find((item) => {
              return item.id == patientId;
            });
          } else if (result.isDismissed) {
            data.patientId = data.payment.patientId;
          }
        });
      } else {
        data.payment.patientId = patientId;
        data.payment.patient = data.patientOptions.find((item) => {
          return item.id == patientId;
        });
      }
    }

    function patientRemoved() {
      data.payment.patient = null;
      data.payment.patientId = null;
    }

    async function paymentPosted() {
      data.payment = await getPayment(data.payment.id);
    }

    async function servicesReceived(items) {
      data.items = items;
    }

    const maxServDate = computed(() => {
      return getNow();
    });

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    const availableCredit = computed(() => {
      let sum = 0;
      data.items.forEach((item) => {
        sum += item.unappliedCredit;
      });
      return sum;
    });

    const postedCredit = computed(() => {
      let sum = 0;
      data.items.forEach((item) => {
        if (item.refundAmount) sum += item.refundAmount;
      });
      if (data.createNewMode) {
        sum += data.payment.unappliedCredit;
      }
      return sum;
    });

    const newCredit = computed(() => {
      return availableCredit.value - postedCredit.value;
    });

    const remainingToPost = computed(() => {
      return data.payment.totalAmount - postedCredit.value;
    });

    function autoPost() {
      data.isDirty = true;
      data.items.forEach((item) => {
        item.refundAmount = undefined;
      });
      if (remainingToPost.value <= 0) {
        return;
      }
      data.items.forEach((item) => {
        item.refundAmount = undefined;
      });
      let postCheck = true;
      let index = 0;
      while (postCheck) {
        let service = data.items[index];
        if (remainingToPost.value > service.unappliedCredit) {
          service.refundAmount = service.unappliedCredit;
        } else {
          service.refundAmount = remainingToPost.value;
          postCheck = false;
        }
        if (index == data.items.length - 1) {
          postCheck = false;
        }
        index++;
      }
    }

    const postedErrorsList = computed(() => {
      let postedErrors: string[] = [];
      if (availableCredit.value < data.payment.totalAmount) {
        postedErrors.push("Refund Amount cannot exceed the Available Credit");
      } else if (
        availableCredit.value &&
        data.createNewMode &&
        postedCredit.value < data.payment.totalAmount
      ) {
        postedErrors.push("It is necessary to distribute the Refund Amount");
      }
      if (postedCredit.value > data.payment.totalAmount) {
        postedErrors.push(
          "The amount you are trying to post exceeds the total Refund amount"
        );
      }

      return postedErrors;
    });

    async function postPayment() {
      let validateResults = await v$.value.$validate();
      if (validateResults) {
        data.postingInProgress = true;
        let paymentId = "";
        if (data.createNewMode) {
          const res = (await addPayment(data.payment)) as any;
          paymentId = res.data;
        } else {
          paymentId = data.payment.id;
        }

        if (paymentId) {
          data.isDirty = false;
          const itemsToPost = data.items
            .filter((c) => {
              return c.refundAmount;
            })
            .map((c) => {
              return {
                RefundAmount: c.refundAmount,
                Id: c.id,
                PaymentId: c.paymentId,
              };
            });
          const postRes = await addPaymentRefundItemRequest({
            entryDate: getUTCnow().toISOString(),
            paymentId: paymentId,
            items: itemsToPost,
          });
          data.payment = await getPayment(paymentId);
          data.previousPayment = JSON.parse(JSON.stringify(data.payment));
          if (data.payment.unappliedCredit > 0) {
            data.canPost = true;
          } else {
            data.canPost = false;
          }
          //TODO clean
          if (paymentsWithRefund.value)
            (paymentsWithRefund.value as any).cleanFilter();

          ctx.emit("paymentPosted");

          Swal.fire("Ok!", "Patient Refund has been posted", "success");

          const patientId = route.query.patientId;
          if (patientId) {
            router.push({
              path: "/patientAccounts/patient/" + patientId + "/",
            });
            return;
          }
          if (data.createNewMode) {
            router.push({
              path: "/Payments/PatientTransactionsList",
            });
          } else {
            router.go(-1);
          }
          data.createNewMode = false;
        }
      }
      data.postingInProgress = false;
    }

    async function cancel() {
      if (postedCredit.value > 0) {
        data.isDirty = true;
      }
      const patientId = route.query.patientId;
      if (patientId) {
        router.push({ path: "/patientAccounts/patient/" + patientId + "/" });
        return;
      }
      if (data.createNewMode) {
        router.push({
          path: "/Payments/PatientTransactionsList",
        });
      } else {
        router.go(-1);
      }
    }

    function cleanPost() {
      data.items.forEach((item) => {
        item.refundAmount = undefined;
      });
    }

    function totalBalanceDueUpdated(totalBalanceDue) {
      data.totalBalanceDue = totalBalanceDue;
    }

    // function typeSelected() {
    //   data.payment.unappliedCredit = null;
    //   data.payment.paymentType = 2;
    //   data.payment.adjustmentReason = 1;
    //   data.payment.reference = "";
    //   data.payment.totalAmount = null;
    //   data.payment.entryDate = getUTCnow().toISOString();
    //   data.payment.paymentDate = getUTCnow().toISOString();
    //   data.payment.accountingDate = getUTCnow().toISOString();
    //   data.items
    //     .filter((item) => {
    //       return item.paymentAmount;
    //     })
    //     .forEach((item) => {
    //       item.paymentAmount = undefined;
    //     });
    // }

    function amountChanged() {
      data.isDirty = true;
    }

    return {
      organizationId,
      data,
      searchPatients,
      patientSelected,
      patientRemoved,
      paymentPosted,
      maxServDate,
      getNow,
      cancel,
      isSaveEnabled,
      servicesReceived,
      availableCredit,
      remainingToPost,
      autoPost,
      postedErrorsList,
      totalBalanceDueUpdated,
      newCredit,
      paymentsWithRefund,
      postPayment,
      cleanPost,
      amountChanged,
      v$,
    };
  },
});
